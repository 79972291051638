import React, { useContext } from 'react';
import { CurrentUserContext } from '../App.js';

const FilePreview = ({ url, isOpen, onClose }) => {

    const { headerData } = useContext(CurrentUserContext);
    if (!isOpen) return null;

    const renderFilePreview = (fileUrl) => {
        if (!fileUrl) return <span>No Content</span>;

        const extension = fileUrl.split('.').pop().toLowerCase();

        if (['jpg', 'jpeg', 'png', 'gif', 'svg', 'webp'].includes(extension)) {
            return <img src={fileUrl} alt="selected file" style={{ height: '100%', width: '100%', objectFit: 'contain' }} />;
        } else if (['mp4', 'webm', 'ogg', 'mov'].includes(extension)) {
            return (
                <video controls style={{ height: '100%', width: '100%', objectFit: 'contain' }}>
                    <source src={fileUrl} type={`video/${extension}`} />
                    Your browser does not support the video tag.
                </video>
            );
        } else if (['mp3', 'wav', 'ogg', 'aac', 'm4a'].includes(extension)) {
            return (
                <audio controls style={{ width: '100%' }}>
                    <source src={fileUrl} type={`audio/${extension}`} />
                    Your browser does not support the audio element.
                </audio>
            );
        } else if (extension === 'pdf') {
            return <embed src={fileUrl} type="application/pdf" style={{ height: '100%', width: '100%' }} />;
        } else if (['doc', 'docx'].includes(extension)) {
            return (
                <iframe
                    src={`https://docs.google.com/gview?url=${encodeURIComponent(fileUrl)}&embedded=true`}
                    style={{ height: '100%', width: '100%', border: 'none' }}
                    title="Document Preview"
                />
            );
        } else if (url?.includes('youtube.com') || url?.includes('youtu.be')) {
            const videoId = url?.split('v=')[1];
            const embedUrl = `https://www.youtube.com/embed/${videoId}`;
            return (
                <iframe
                    width="100%"
                    height="100%"
                    src={embedUrl}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="YouTube Video"
                />
            );
        } else {
            return <span>Unsupported file format</span>;
        }
    };

    return (
        <div
            style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100vw',
                height: '100vh',
                backgroundColor: 'rgba(0, 0, 0, 0.8)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 11111,
            }}
        >
            <div
                style={{
                    position: 'relative',
                    width: '90%',
                    height: '90%',
                    backgroundColor: '#fff',
                    borderRadius: '8px',
                    overflow: 'hidden',
                }}
            >
                <button
                    onClick={() => onClose()}
                    style={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                        background: headerData.primary_color,
                        color: '#fff',
                        border: 'none',
                        padding: '8px 16px',
                        cursor: 'pointer',
                        zIndex: 1001,
                        borderRadius: '4px'
                    }}
                >
                    Close
                </button>
                <div style={{ width: '100%', height: '100%' }}>{renderFilePreview(url)}</div>
            </div>
        </div>
    );
};

export default FilePreview;
